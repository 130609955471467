import { GetStaticProps, NextPage } from "next";
import ApiEngine from "../api-engine";
import { PageLayoutProps } from "../components/PageLayout";
import Custom404PageComponent from "../components/pages/Custom404PageComponent";
import NavigationProvider from "../context/NavigationContext";

export interface ICustom404PageProps extends PageLayoutProps {}

const Custom404Page: NextPage<ICustom404PageProps> = (props) => {
  return (
    <NavigationProvider sections={[]} menu={props.menu}>
      <Custom404PageComponent {...props} />
    </NavigationProvider>
  );
};
export const getStaticProps: GetStaticProps<ICustom404PageProps> = async (
  context
) => {
  const menu = await ApiEngine.menu.getOrderedMenu();

  return {
    props: { menu },
    revalidate: 30,
  };
};

export default Custom404Page;
