import { FC } from "react";
import { ICustom404PageProps } from "../../pages/404";
import Button from "../Button";
import Container from "../Container";
import PageLayout from "../PageLayout";
import Typo from "../Typo";
import styles from "./Custom404PageComponent.module.scss";

const Custom404PageComponent: FC<ICustom404PageProps> = ({ menu, slug }) => {
  return (
    <PageLayout menu={menu} slug={slug}>
      <Container className={styles.container}>
        <Typo variant="h1" color="primary" className={styles.title}>
          404
        </Typo>
        <Typo variant="h2" color="secondary" className={styles.description}>
          Oups! Cette page n&apos;existe pas.
        </Typo>
        <Button text="Retour à l'index" href="/" />
      </Container>
    </PageLayout>
  );
};

Custom404PageComponent.defaultProps = {};

export default Custom404PageComponent;
